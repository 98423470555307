<template>
  <div class="venue-card">
    <div class="venue-card__row">
      <span class="venue-card__name">{{ name }}</span>
      <span class="venue-card__country">{{ countryName }}</span>
    </div>
    <div class="venue-card__row">
      <span class="venue-card__id">{{ id }}</span>
      <span :class="statusClass">
        {{ statusName }}
      </span>
    </div>
  </div>
</template>

<script>
import { VenueStatusEnum } from "@/helpers/enums";
import { VENUE_STATUS_NAME_MAP } from "@/helpers/const";
import { getCountry } from "countries-and-timezones";

export default {
  name: "VenueCard",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "-",
    },
    country: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: VenueStatusEnum.INACTIVE,
    },
    isLoginDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusName() {
      return VENUE_STATUS_NAME_MAP[this.status];
    },
    countryName() {
      return getCountry(this.country).name;
    },
    statusClass() {
      return {
        "m-green": this.status === VenueStatusEnum.LIVE,
        "m-yellow": this.status === VenueStatusEnum.PENDING,
        "m-red": this.status === VenueStatusEnum.INACTIVE,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.venue-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  line-height: 24px;
  background: $white;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $secondary-500;
  }

  &__id {
    color: $secondary-400;
  }

  &__country {
    color: $secondary-500;
  }

  .m-green {
    color: $success;
  }
  .m-yellow {
    color: #d59d00;
  }
  .m-red {
    color: $danger;
  }
}
</style>
