<template>
  <div class="superadmin-page">
    <IconButton is-raised icon="plus" class="mb-32" @click="goToCreation">
      Create venue
    </IconButton>
    <Title class="mb-32">Venues</Title>
    <div class="grid-2 mb-32">
      <Select v-model="statusFilter" :options="statusOptions" label="Status" />
    </div>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <div v-else-if="!venues.length" class="empty">
      There are no venues with the selected status
    </div>
    <div v-else class="grid-2">
      <VenueCard
        v-for="venue in venues"
        :key="venue.id"
        :id="venue.id"
        :name="venue.name"
        :status="venue.status"
        :country="venue.country"
        :is-login-disabled="isLoginDisable(venue.id)"
        @click.native="handleVenueRedirect(venue.id)"
        @login="handleVenueRedirectOwner"
      />
    </div>
  </div>
</template>

<script>
import { VenueStatusEnum } from "@/helpers/enums";
import { VENUE_STATUS_NAME_MAP } from "@/helpers/const";
import { mapActions, mapState } from "vuex";
import VenueCard from "@/components/venues/VenueCard";

export default {
  name: "SuperadminPage",
  components: { VenueCard },
  data() {
    return {
      isLoading: false,
      statusOptions: [
        {
          name: "All",
          value: null,
        },
        ...Object.values(VenueStatusEnum).map((status) => ({
          name: VENUE_STATUS_NAME_MAP[status],
          value: status,
        })),
      ],
    };
  },
  computed: {
    ...mapState({
      venues: (state) => state.superadmin.list,
      userVenues: (state) => state.venues.list,
    }),
    statusFilter: {
      get() {
        return this.$store.state.superadmin.statusFilter;
      },
      set(newValue) {
        if (this.statusFilter !== newValue) {
          this.$store.commit("superadmin/SET_STATUS_FILTER", newValue);
          this.refetch();
        }
      },
    },
  },
  created() {
    this.refetch();
  },
  methods: {
    ...mapActions({
      fetchVenue: "venues/fetchVenue",
      fetchVenues: "superadmin/fetchVenues",
    }),
    async refetch() {
      try {
        this.isLoading = true;
        await this.fetchVenues();
      } finally {
        this.isLoading = false;
      }
    },
    goToCreation() {
      this.$router.push({
        name: "SuperadminCreate",
      });
    },
    isLoginDisable(id) {
      return !!this.userVenues.find((item) => item.id === id);
    },
    handleVenueRedirect(venueId) {
      const routeData = this.$router.resolve({
        name: "EditVenue",
        query: { venue: venueId },
      });
      window.location.href = routeData.href;
    },
  },
};
</script>

<style lang="scss" scoped>
.superadmin-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
